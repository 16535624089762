import * as React from 'react';
import { Link } from 'gatsby';
import styled from 'react-emotion';

import Container from '../components/Container';
import IndexLayout from '../layouts';
import Page from '../components/Page';
import SplitText from '../components/SplitText';

import { colors, fonts } from '../styles/variables';

import { darken } from '../util/style';

const StyledImg = styled.img`
	margin: auto;
	opacity: 0.95;
	width: 70%;
`;

const P = styled.p`
	color: ${darken(colors.dark, 5)};
	font-family: ${fonts.sansSerif};
	font-size: 1.5em;
	margin: auto;
	padding: 20px;
`;

const StyledLink = styled(Link)`
	color: ${colors.lightAccent};
	font: ${fonts.sansSerif};
	font-size: 1.5rem;
	font-weight: 600;
	padding: 10px;
	&:hover,
	&:focus {
		color: ${colors.light};
		font-weight: 600;
		text-decoration: none;
	}
`;

const Group = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const NotFoundPage = () => (
	<IndexLayout>
		<Page>
			<Container>
				<Group>
					<SplitText text="404: Page Not Found" timeout={2000} />

					<P>
						You are outside of space-time. <StyledLink to="/">Go back.</StyledLink>
					</P>
					<StyledImg src="https://media.giphy.com/media/Yqn9tE2E00k4U/giphy.gif" />
				</Group>
			</Container>
		</Page>
	</IndexLayout>
);

export default NotFoundPage;
